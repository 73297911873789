<template src="./DetailAnnouncementResearchProfessor.html"></template>

<script>
import ChosenUniversity from "../../components/ChosenUniversity/ChosenUniversity";
import announcementService from "../../services/Announcement";
import groupAnnouncementServices from "../../services/GroupAnnouncement";

export default {
  name: "detailAnnouncementResearchProfessor",
  components: {
    ChosenUniversity,
  },
  data() {
    return {
      announcement: {
        announcement: {
          name: "",
          institution: { name: "" },
          description: "",
          category: { name: "" },
          dateInit: "",
          dateFinish: "",
          id: null,
        },
      },
      myParticipatingGroups: [],
      showViewMore: false,
      applicationStatus: false,
      participatingGroups: true,
      infoGroup: { id: "" },
      modalBackingOut: false,
    };
  },
  created() {
    this.getSpecificAnnouncement();
  },
  methods: {
    async getSpecificAnnouncement() {
      try {
        this.announcement = await announcementService.getSpecificAnnouncement(
          this.$route.params.id
        );
        const infoGetMygroups = {
          state: true,
          announcementId: this.announcement.announcement.id,
        };
        this.myParticipatingGroups =
          await announcementService.getMyParticipatingGroups(infoGetMygroups);
      } catch (e) {
        console.error("error", e);
      }
    },
    nextApplicationStatus(group) {
      this.participatingGroups = false;
      this.applicationStatus = true;
      this.infoGroup = group;
    },
    previousApplicationStatus() {
      this.infoGroup = {};
      this.participatingGroups = true;
      this.applicationStatus = false;
    },
    showModalBackingOut() {
      this.modalBackingOut = true;
    },
    async backingOut() {
      try {
        await groupAnnouncementServices.deleteGroupPostulation(
          this.infoGroup.id
        );
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se retiro el grupo de la convocatoria con exito!",
          type: "is-success",
        });
        this.modalBackingOut = false;
        this.getSpecificAnnouncement();
        this.infoGroup = {};
        this.participatingGroups = true;
        this.applicationStatus = false;
      } catch (error) {
        console.error("error", error);
      }
    },
  },
  computed: {
    colorByStatus() {
      return (status) => {
        switch (status) {
          case false:
            return "dotRed";
          case true:
            return "dotGreen";
          default:
            null;
            return "dotYellow";
        }
      };
    },
    nameByStatus() {
      return (status) => {
        switch (status) {
          case false:
            return "Rechazado";
          case true:
            return "Aceptado";
          default:
            null;
            return "En evaluación";
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped src="./DetailAnnouncementResearchProfessor.scss"></style>